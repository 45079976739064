let Merchant = function(tokenClient, serviceAddr) {
    let TokenClient = tokenClient
    let ServiceBaseAddress = serviceAddr

    /**
     * 获取店铺申请状态
     * @param success
     * @param error
     * @constructor
     */
    this.MerchantState = function (success, error) {
        var url = ServiceBaseAddress + '/api/Merchant';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 机构详情
     * @param success
     * @param error
     * @constructor
     */
     this.ApplyDetail = function (applyId ,success, error) {
        var url = ServiceBaseAddress + `/api/Organization/ApplyDetail/${applyId}`;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取店铺设置详情
     * @param organizationId
     * @param success
     * @param error
     * @constructor
     */
     this.MerchantDetail = function (organizationId, success, error) {
        var url = ServiceBaseAddress + '/api/Merchant/Set/'+ organizationId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 设置店铺信息
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.MerchantSet = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Merchant/Set';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 设置分红倍数
     * @param ShareMultiplying
     * @param success
     * @param error
     * @constructor
     */
     this.ShareMultiplying = function (ShareMultiplying, success, error) {
        var url = ServiceBaseAddress + '/api/Organization/ShareMultiplying/'+ShareMultiplying;
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取分红倍数
     * @param success
     * @param error
     * @constructor
     */
     this.GetShareMultiplying = function (success, error) {
        var url = ServiceBaseAddress + '/api/Organization/ShareMultiplying';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取绩效设置
     * @param success
     * @param error
     * @constructor
     */
     this.GetTeamPerformance = function (success, error) {
        var url = ServiceBaseAddress + '/api/TeamPerformance/Setting';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 设置绩效信息
     * @param item
     * @param success
     * @param error
     * @constructor
     */
     this.TeamPerformanceSet = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/TeamPerformance/Setting';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取商家分类
     * @param success
     * @param error
     * @constructor
     */
    this.MerchantCategory = function (success, error) {
        var url = ServiceBaseAddress + '/api/CommodityCategory/Enable/';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    },
    this.getQualificationCategory = function (categoryIds,success, error) {
        var url = ServiceBaseAddress + '/api/CommodityCategory/Qualification/' + categoryIds;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    },    
    this.TeamPerformanceApply = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Merchant/Apply';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 商家申请
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.MerchantApply = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Merchant/Apply';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.GetShopQualificationData = function (categoryIds,success, error) {
        var url = ServiceBaseAddress + '/api/Merchant/GetShopQualification/' + categoryIds;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.GetExistsMerchantName = function (categoryName,success, error) {
        var url = ServiceBaseAddress + '/api/Merchant/ExistsMerchant/' + categoryName;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    
}
export { Merchant }
