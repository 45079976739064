import { render, staticRenderFns } from "./Distributor.vue?vue&type=template&id=181afc25&scoped=true&"
import script from "./Distributor.vue?vue&type=script&lang=js&"
export * from "./Distributor.vue?vue&type=script&lang=js&"
import style0 from "./Distributor.vue?vue&type=style&index=0&id=181afc25&prod&scoped=true&lang=css&"
import style1 from "./Distributor.vue?vue&type=style&index=1&id=181afc25&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "181afc25",
  null
  
)

export default component.exports